
const routes = [
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            { path: '', component: () => import('@/views/HomeView'), meta: { title: 'Coscienza Etica' } },
            { path: '/who', component: () => import('@/views/WhoView'), meta: { title: 'Chi siamo' } },
            { path: '/project', component: () => import('@/views/ProjectView'), meta: { title: 'Il progetto' } },
            { path: '/zodiac', component: () => import('@/views/ZodiacView'), meta: { title: 'Zodiaco' } },
            { path: '/festivities', component: () => import('@/views/FestivitiesView'), meta: { title: 'Pleniluni e Feste' } },
            { path: '/archive/:tab', component: () => import('@/views/ArchiveView'), meta: { title: 'Archivio' } },
            { path: '/ethic', component: () => import('@/views/EthicView'), meta: { title: 'Etica vivente' } },
            { path: '/articles/:page', component: () => import('@/views/themes/ArticlesView'), meta: { title: 'Attualità' } },
            { path: '/insights/:page', component: () => import('@/views/themes/InsightsView'), meta: { title: 'Editoriale' } },
            { path: '/article/:id/:title', component: () => import('@/views/ArticleView'), meta: { title: 'Articolo Attualità' } },
            { path: '/insight/:id/:title', component: () => import('@/views/ArticleView'), meta: { title: 'Approfondimento Editoriale' } },
            { path: '/hierarchy', component: () => import('@/views/themes/HierarchyView'), meta: { title: 'Maestri e Gerarchia' } },
            { path: '/meditation', component: () => import('@/views/themes/MeditationView'), meta: { title: 'Meditazione' } },
            { path: '/triangles', component: () => import('@/views/themes/TrianglesView'), meta: { title: 'Triangoli' } },
            { path: '/invocation', component: () => import('@/views/themes/InvocationView'), meta: { title: 'Invocazione' } },
            { path: '/event/:id', component: () => import('@/views/EventView'), meta: { title: 'Evento' } },
            { path: '/zodiac/:name', component: () => import('@/views/ZodiacSign'), meta: { title: 'Zodiaco' } },
            { path: '/newsletter', component: () => import('@/views/NewsletterView'), meta: { title: 'NewsLetter' } },
            { path: '/mantra/:id', component: () => import('@/views/MantraView'), meta: { title: 'Mantra' } },
            { path: '/talk/:id', component: () => import('@/views/TalkView'), meta: { title: 'Discorso festa' } }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFoundView')
    }
]

export default routes
