<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import 'src/css/variables';

#app {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
